@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600;700&display=swap');

body {
    color: $black-300;
    background-color: $white;
	font-family: $font-primary;
	font-weight: 500;
    font-size: 15px;
    line-height: 1.65;
}
::selection {
	color: $white;
	background-color: darken($color-primary, 5);
	text-shadow: none;
}
:focus {
    outline: 0;
}

/*------------------------------------------------------------------
 # default styles
-------------------------------------------------------------------*/
@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1170px;
    }
}

.section-padding {
    padding: 80px 0;
}
.section-title {
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.badge {
    color: $white !important;
    font-size: 12px;
    font-weight: 600;
    padding: .45em .8em;
    border-radius: 4px;
    &.badge-changed {background-color: #3A9CFF}
    &.badge-added {background-color: #00CE92}
    &.badge-removed {background-color: #973CFF}
    &.badge-security {background-color: #9C275F}
    &.badge-depreciate {background-color: #FF3158}
}

.divider-text {
    position: relative;
    text-align: center;
    z-index: 1;
    span {
        padding: 0 15px;
    }
    &::after {
        position: absolute;
        content: "";
        height: 1px;
        width: 100%;
        background-color: $gray;
        left: 0;
        top: calc(50% - 0.03125rem);
        z-index: -1;
    }
}

.has-shapes {
    position: relative;
    z-index: 1;
    .shape-xl,
    .shape-lg,
    .shape-md,
    .shape-sm,
    .shape-sm-2,
    .shape-xs,
    .shape-xs-2 {
        position: absolute;
        pointer-events: none;
        z-index: -1;
    }
    .shape-xl {
        height: 105px;
        width: 105px;
    }
    .shape-lg {
        height: 93px;
        width: 93px;
    }
    .shape-md {
        height: 76px;
        width: 76px;
    }
    .shape-sm {
        height: 55px;
        width: 55px;
    }
    .shape-sm-2 {
        height: 35px;
        width: 35px;
    }
    .shape-xs {
        height: 26px;
        width: 26px;
    }
    .shape-xs-2 {
        height: 15px;
        width: 15px;
    }
}

.has-shadow {
    padding-bottom: 40px;
    position: relative;
    z-index: 1;
    &::after {
        position: absolute;
        content: "";
        height: calc(100% - 40px);
        width: calc(100% - 40px);
        bottom: 0;
        background-color: #E0E0E0;
        z-index: -1;
        border-radius: 5px;
    }
    &.shadow-light::after {
        background-color: #f6f6f6;
    }
    @include breakAt(md-device) {
        padding-bottom: 25px;
        &::after {
            height: calc(100% - 25px);
            width: calc(100% - 25px);
        }
    }
    &.has-shapes {
        .shape-1 {
            top: -30px;
        }
        .shape-2 {
            bottom: 0;
            z-index: 2;
            @include breakAt(md-device) {
                height: 80px;
                width: 80px;
            }
        }
        .shape-3 {
            bottom: calc(50% - 40px);
            z-index: 2;
            height: 81px;
            width: 81px;
            @include breakAt(md-device) {
                height: 50px;
                width: 50px;
            }
        }
    }
    &.shadow-right {
        padding-right: 40px;
        &::after {
            right: 0;
        }
        @include breakAt(md-device) {
            padding-right: 25px;
            padding-bottom: 25px;
            &::after {
                height: calc(100% - 25px);
                width: calc(100% - 25px);
            }
        }
        &.has-shapes {
            .shape-1 {
                left: -30px;
            }
            .shape-2 {
                left: -40px;
            }
            .shape-3 {
                right: 0;
            }
        }
    }
    &.shadow-left {
        padding-left: 40px;
        &::after {
            left: 0;
        }
        @include breakAt(md-device) {
            padding-left: 25px;
            padding-bottom: 25px;
            &::after {
                height: calc(100% - 25px);
                width: calc(100% - 25px);
            }
        }
        &.has-shapes {
            .shape-1 {
                right: -30px;
                left: auto;
            }
            .shape-2 {
                right: -40px;
                top: auto;
            }
            .shape-3 {
                left: 0;
            }
        }
    }
}

.check-list {
    li {
        position: relative;
        padding-left: 25px;
        svg {
            position: absolute;
            left: 0;
            top: 6px;
        }
    }
}
.check-list-2, .check-list-primary {
    li {
        position: relative;
        padding-left: 35px;
        font-size: 16px;
        font-weight: 600;
        span {
            position: absolute;
            left: 0;
            top: 6px;
            z-index: 1;
            svg {
                height: 8px;
                width: 12px;
                color: #33B27C;
                position: absolute;
                top: 3px;
                left: 8px;
            }
            &::after {
                position: absolute;
                content: "";
                height: 27px;
                width: 27px;
                left: 0;
                top: -7px;
                background-color: #E1F4EC;
                border-radius: 50px;
                z-index: -1;
            }
        }
    }
}
.check-list-primary {
    li {
        padding-left: 30px;
        font-size: 14px;
        font-weight: 500;
        span {
            top: 6px;
            svg {
                height: 7px;
                width: 11px;
                color: $color-primary;
                top: 3px;
                left: 5px;
            }
            &::after {
                height: 20px;
                width: 20px;
                top: -4px;
                background-color: rgba($color-primary, .15);
            }
        }
    }
}

.bullet-list {
    li {
        position: relative;
        padding-left: 18px;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        &::after {
            position: absolute;
            content: "";
            height: 7px;
            width: 7px;
            background-color: $color-primary;
            border-radius: 50px;
            left: 0;
            top: 8px;
        }
    }
    &.bullet-list-dark {
        li::after {
            background-color: $black-200;
        }
    }
}

.tabs-navbar {
    .nav-link {
        &.active,
        &:hover {
            color: $color-primary;
        }
    }
    &.nav-tabs {
        .nav-item {
            margin-bottom: -1px;
            z-index: 2;
        }
        .nav-link:hover,
        .active {
            border-bottom: 1px solid $color-primary !important;
            @include breakAt(sm-device) {
                border-bottom: 0 !important;
                padding: 0 !important;
            }
        }
        .nav-link {
            @include breakAt(sm-device) {
                padding: 0px !important;
                margin-bottom: 15px;
            }
        }
    }
}

.form-control {
    height: 50px;
    border-color: $gray;
    border-radius: 5px;
    padding: 15px 20px;
    &:focus {
        box-shadow: none;
        border-color: $black-800;
    }
}

.custom-checkbox {
	margin-top: 15px;
	.custom-control-label::before {
        border-radius: 0;
	}
	.custom-control-input:checked~.custom-control-label::before,
	.custom-control-input:not(:disabled):active~.custom-control-label::before {
		background-color: $color-primary;
		border-color: $color-primary;
        box-shadow: none;
	}
	.custom-control-input:focus:not(:checked)~.custom-control-label::before {
		border-color: $color-primary;
        box-shadow: none;
	}
	.custom-control-input:disabled:checked~.custom-control-label::before {
		background-color: rgba($color-primary, .5);
		border-color: rgba($color-primary, .2);
        box-shadow: none;
	}
}

.colored-icon {
    &.icon-1 {
        color: $color-primary;
    }
    &.icon-2 {
        color: $color-secondary;
    }
    &.icon-3 {
        color: $color-tertiary;
    }
    &.icon-4 {
        color: #497CFF;
    }
}

/*------------------------------------------------------------------
 # helper classes
-------------------------------------------------------------------*/
.font-primary {font-family: $font-primary}

.rounded {border-radius: 5px}
.shadow {box-shadow: 0px 25px 65px rgba($black, 0.05) !important}

.border-top {border-top: 1px solid #E3E3E3 !important}
.border-bottom {border-bottom: 1px solid #E3E3E3 !important}
.border-left {border-left: 1px solid #E3E3E3 !important}
.border-right {border-right: 1px solid #E3E3E3 !important}
@include breakAt(xl-device){.border-xl-0 {border: 0 !important}}
@include breakAt(lg-device){.border-lg-0 {border: 0 !important}}
@include breakAt(md-device){.border-md-0 {border: 0 !important}}
@include breakAt(sm-device){.border-sm-0 {border: 0 !important}}

.font-weight-500 {font-weight: 500}
.font-weight-600 {font-weight: 600}

.text-underline {
    text-decoration: 1px dashed;
}
a.text-underline:hover {
    text-decoration-style: solid;
}

.has-bg-brash {
    background-size: 1200px;
    background-repeat: repeat-x;
}
.no-bg-image {
    background-image: none !important;
}
.bg-brash-top {
    background-position: center top;
}
.bg-brash-bottom {
    background-position: center bottom;
}
.bg-brash-y {
    background-position: center top, center bottom;
}
.bg-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.text-light-gray {color: $light-gray}
.bg-light-gray {background-color: $light-gray}
.text-primary {color: $color-primary !important}
a.text-primary:hover {color: darken($color-primary, 10) !important}
.bg-primary {background-color: $color-primary !important}

.text-primary {color: $color-primary !important}
.bg-primary {background-color: rgba($color-primary, .5) !important}
.bg-primary-fill {background-color: $color-primary !important}

.text-secondary {color: rgba($color-secondary, .5) !important}
.bg-secondary {background-color: rgba($color-secondary, .5) !important}
.text-secondary-fill {color: $color-secondary !important}
.bg-secondary-fill {background-color: $color-secondary !important}

.text-tertiary {color: rgba($color-tertiary, .5) !important}
.bg-tertiary {background-color: rgba($color-tertiary, .5) !important}
.text-tertiary-fill {color: $color-tertiary !important}
.bg-tertiary-fill {background-color: $color-tertiary !important}

.bg-black {background-color: $black}
.text-black {color: $black}
.text-black-100 {color: $black-100}
.bg-black-100 {background-color: $black-100}
.bg-black-200 {background-color: $black-200}
.text-black-200 {color: $black-200}
.bg-black-300 {background-color: $black-300}
.text-black-300 {color: $black-300}
.text-black-400 {color: $black-400}
.bg-black-400 {background-color: $black-400}
.text-black-500 {color: $black-500}
.bg-black-500 {background-color: $black-500}
.text-black-600 {color: $black-600}
.bg-black-600 {background-color: $black-600}
.text-black-700 {color: $black-700}
.bg-black-700 {background-color: $black-700}
.text-black-800 {color: $black-800}
.bg-black-800 {background-color: $black-800}
.text-black-900 {color: $black-900}
.bg-black-900 {background-color: $black-900}

a.text-black-200:hover,
a.text-black-300:hover,
a.text-black-400:hover {color: $color-primary}

/*------------------------------------------------------------------
 # spacing classes
-------------------------------------------------------------------*/
$step: 5; // minimum value
$max: 121; // maximum value (+)1
@for $i from 1 through ceil($max/$step) {
    $value: ($i - 1)*$step;
    .m-#{$value}{margin: #{$value}px};
    .mt-#{$value}{margin-top: #{$value}px};
    .mb-#{$value}{margin-bottom: #{$value}px};
    .ml-#{$value}{margin-left: #{$value}px};
    .mr-#{$value}{margin-right: #{$value}px};
    .p-#{$value}{padding: #{$value}px};
    .pt-#{$value}{padding-top: #{$value}px};
    .pb-#{$value}{padding-bottom: #{$value}px};
    .pl-#{$value}{padding-left: #{$value}px};
    .pr-#{$value}{padding-right: #{$value}px};
}